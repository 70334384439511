/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import jsYaml from 'js-yaml';
import axios from 'axios';
import useSharedTabState from '../../hooks/tabState';
// import oepBannerIcom from '../../images/icons/oep-banner-close.svg';
import envConstants from '../../globals/constants/environment-constants';
import useSharedOepData from '../../hooks/oepData';
import CountDown from './CountDown';
import { getBrowserStorage } from '../../common';

const OepBanner = () => {
  const { active } = useSharedTabState();
  const { stateAbbr, setStateAbbr, countDownData, setCountDownData } = useSharedOepData();
  const [dateLeft, setDateLeft] = useState(0);
  // const [OEPConfig, setOEPConfig] = useState({});
  const [oepPeriods, setOepPeriods] = useState({});
  const [activeOepBannerConfig, setActiveOepBannerConfig] = useState(null);
  const [isDuringOep, setIsDuringOep] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [isShowCountDown, setIsShowCountDown] = useState(false);

  const getCountdownDate = (to) => {
    const startDate = moment();
    const endDate = moment(to);
    return startDate.isBefore(endDate) ? endDate.diff(startDate, 'days') : 0;
  };

  const setOepOptions = (oepPeriodsData: any) => {
    const oepPeriod = oepPeriodsData[stateAbbr] || oepPeriodsData.DEFAULT;
    setIsDuringOep(
      !!oepPeriod &&
        moment().isSameOrAfter(moment(oepPeriod.from)) &&
        moment().isSameOrBefore(moment(oepPeriod.to)),
    );
    // Set date left
    setDateLeft(getCountdownDate(oepPeriod.to));
  };

  const setCountDownExisting = (oepPeriod, countDownDate) => {
    if (countDownDate) {
      setIsShowCountDown(
        !!oepPeriod &&
          moment().isSameOrAfter(moment(countDownDate.from)) &&
          moment().isSameOrBefore(moment(countDownDate.to)),
      );
    } else {
      setIsShowCountDown(false);
    }
  };

  const loadOEPConfig = async () => {
    try {
      const isLocal = window.location.hostname === 'localhost';
      const oepConfigUrl = `${
        isLocal ? 'https://www.qa.ehealthinsurance.com' : envConstants.SITE_URL
      }/ifp-api/misc/instant-config/yaml/oep`;
      axios
        .get(oepConfigUrl)
        .then((response) => {
          const oepConfig = jsYaml.load(response.data);
          // setOEPConfig(oepConfig);
          setOepPeriods(oepConfig.periods || {});
          setOepOptions(oepConfig.periods);

          const stateOepBannerConfig = oepConfig.banner[stateAbbr] || oepConfig.banner.DEFAULT;
          if (stateOepBannerConfig) {
            const oepBannerSettings =
              stateOepBannerConfig.find((item) =>
                moment().isBetween(moment(item.when[0]), moment(item.when[1])),
              ) || null;
            setActiveOepBannerConfig(oepBannerSettings);
            // Set count down dat
            if (oepBannerSettings) {
              setCountDownData(oepBannerSettings.countdown);
              setCountDownExisting(oepConfig.periods, oepBannerSettings.countdown);
            }
          } else {
            setActiveOepBannerConfig(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log('Fetch OEP configration fail.');
    }
  };

  const getGeoLocation = () => {
    try {
      const isLocal = window.location.hostname === 'localhost';
      const urlSearchParams = new URLSearchParams(window.location.search);
      const ipFromURL = urlSearchParams.get('_ipAddress');
      const ipParameter = ipFromURL ? `ipAddress=${ipFromURL}` : '';
      axios
        .get(
          `${
            isLocal ? 'https://www.qa.ehealthinsurance.com' : envConstants.SITE_URL
          }/ifp-api/misc/geo-location?${ipParameter}`,
        )
        .then((res) => {
          const stateAbbrFromApi = res.data.state;
          if (stateAbbrFromApi) {
            setStateAbbr(stateAbbrFromApi);
          } else {
            // if stateAbbr no existing in api
            setStateAbbr('DEFAULT');
          }
        });
    } catch (e) {
      console.log('Fetch geo location fail.');
    }
  };

  const handleCloseOepBanner = () => {
    setIsShow(false);
  };
  useEffect(() => {
    /* Check stateAbbr and load oep config if stateAbbr existing,
    otherwise get geo location and trigger the stateAbbr change if there is existing stateAbbr under IP */
    const zipCode = getBrowserStorage('zipValue');
    if (stateAbbr) {
      loadOEPConfig();
    } else if (!zipCode) {
      getGeoLocation();
    }
  }, [stateAbbr]);
  return (
    <div id="oep-banner-container" className={active === 1 && isDuringOep ? 'show' : ''}>
      <div className={`w-full bg-[#0E3B12] ${isShow ? '' : 'hidden'}`}>
        <div className="max-w-screen-xl mx-auto oep-banner-inner">
          <div className="text-white text-base block md:flex flex-col lg:flex-row items-center justify-center text-left md:text-center py-2 px-4">
            <p className="flex gap-2 text-16 font-poppin items-center justify-center">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_205_87359)">
                  <path
                    d="M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24Z"
                    fill="#4DCB2A"
                  />
                  <path
                    d="M12.5004 21.7846C17.9043 21.7846 22.285 17.4038 22.285 11.9999C22.285 6.59605 17.9043 2.21533 12.5004 2.21533C7.09654 2.21533 2.71582 6.59605 2.71582 11.9999C2.71582 17.4038 7.09654 21.7846 12.5004 21.7846Z"
                    fill="white"
                  />
                  <path
                    d="M12.6587 12.7384C12.1898 12.7384 11.8076 12.3563 11.8076 11.8874V4.68921C11.8076 4.22029 12.1898 3.83813 12.6587 3.83813C13.1276 3.83813 13.5098 4.22029 13.5098 4.68921V11.8892C13.5098 12.3581 13.1276 12.7403 12.6587 12.7403V12.7384Z"
                    fill="#0C6E1E"
                  />
                  <path
                    d="M8.82569 16.2018C8.59861 16.2018 8.38446 16.1132 8.22384 15.9526C8.06322 15.7919 7.97461 15.5778 7.97461 15.3507C7.97461 15.1236 8.06322 14.9095 8.22384 14.7489L8.23861 14.7359L11.9512 11.3926C12.2835 11.0695 12.8171 11.0713 13.1457 11.3999C13.478 11.7323 13.478 12.2713 13.1457 12.6036L13.1309 12.6166L9.4183 15.9599C9.25769 16.115 9.04907 16.2018 8.82384 16.2018H8.82569Z"
                    fill="#0C6E1E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_205_87359">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
              <div className="inline md:flex max-w-[270px] md:max-w-full leading-24 gap-2">
                {isShowCountDown ? <CountDown /> : ''}
                <span
                  className="inline md:flex"
                  dangerouslySetInnerHTML={{
                    __html: activeOepBannerConfig
                      ? isShowCountDown
                        ? (activeOepBannerConfig.message || '').replace('COUNT_DOWN_TIME', '')
                        : (activeOepBannerConfig.message || '').replace(
                            'COUNT_DOWN days',
                            `<b class="mx-1">${dateLeft}</b> day${dateLeft > 1 ? 's' : ''}`,
                          )
                      : '',
                  }}
                />
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OepBanner;
